import type { ProductDetailData } from '@/types/api'

export enum SortRuleEnum {
  SCORE = 'score',
  PRICE_LOW = 'multi_curr_price_low',
  PRICE_HIGH = 'multi_curr_price_high',
  POPULAR = 'popular',
  DISTANCE = 'distance',
  POPULAR_OVER_DISTANCE = 'popular_over_distance',
  NEW_RELEASE = 'new_release',
}

export enum FilterTimeOptionsEnum {
  NOW = 'now',
  TONIGHT = 'tonight',
  TOMORROW = 'tomorrow',
  ALL = 'all',
}

export enum FilterTimeRangeTypesEnum {
  MIDNIGHT = 1,
  MORNING = 2,
  NOON = 3,
  AFTERNOON = 4,
  NIGHT = 5,
}

export interface GetProductsReqParams {
  filter_regionid: number
  sortby: SortRuleEnum
  category_filter_ids?: string // eg: 1,2,3,4
  category_id?: number
  currency_id?: number
  distance?: number
  endtime?: string
  filter_bid?: string
  filter_keyword?: string
  filter_tag?: string // eg: 1,2,3,4
  filter_tags?: string // [[1002,1001],[1005]] -> meaning: (1002 or 1001) and (1005)
  filter_time?: FilterTimeOptionsEnum
  keyword_context?: string
  lat?: number
  limit?: number
  lng?: number
  max_branch_rating?: number
  max_price?: number
  min_branch_rating?: number
  min_price?: number
  partner?: string
  size?: number
  start?: number
  starttime?: string
  sub_category_id?: number
  time_range_types?: FilterTimeRangeTypesEnum[]
}

export interface GetProductsReqParamsWithTags extends Omit<GetProductsReqParams, 'filter_tags'> {
  filter_tags?: number[][]
}

export interface GetProductInfoReqParams {
  count?: number
  from?: string
  lat?: number
  lng?: number
}

export interface GetProductCommentsParams {
  size?: number
  sortby?: string
  start?: number
  tag_id?: number
}

export interface GetProductAdditionAndUpgradeParams {
  arrival_time: string
  sale_region_id?: number
}

export interface GetCrossDaysDataParams {
  arrival_time?: string
  sale_region_id?: number
}

export enum ProductDetailHtmlTypeEnum {
  HTML = 1,
  IMAGE = 2,
  YOUTUBE = 3,
  UNORDERED_LIST = 4,
  ORDERED_LIST = 5,
}
export interface ProductContentData {
  type: ProductDetailHtmlTypeEnum
  meta?: string
  list?: string[]
  mediaWidth?: number
  mediaHeight?: number
  attr?: any
}

export interface UiProductInfo extends ProductDetailData {
  product_intro?: ProductContentData[]
  purchase_notice?: ProductContentData[]
}

export interface UiProductSelectedData {
  dateTime: string
  price: number
  originPrice: number
}

export interface GetProductPromosReqParams {
  amount: string | number
  booking_time: string
  sale_region_id?: number
}

/**
 * @description: 產品中所有的 Tag ID
 * @reference: 參照後端寫死在 code 的定義: https://github.com/myfunnow/funnow-go/blob/master/api/entities/edb/product_tag.go
 */
export enum ProductTagIdEnum {
  REST = 1001,
  ACCOMMODATION = 1002,
  FEMALE = 1003,
  RESTAURANT = 1005,
  MASSAGE = 1100,
  NEW_RELEASE = 10001,
  PRODUCT_NEW_RELEASE = 10002,
  VIP = 10262,
  HAIR = 10322,
  BAR = 10357,
  TAKEOUT = 10392,
  LIMIT_RELEASE = 10694,
  HOT = 10703,
  GIFT = 11267,
  TWELVE_H = 10576,
  MOVIE = 11403,
  GHA12H = 11575,
  HOT_SPRING = 10265,
  EXCLUSIVE_GIFT = 12149,
}
